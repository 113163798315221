var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('form.name')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
          },attrs:{"placeholder":"James J. Jameson","type":"text","border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("UUID")]),_c('vs-input',{staticClass:"shadow-lg",attrs:{"placeholder":"1-2-3","type":"text","border":""},model:{value:(_vm.typeform.uuid),callback:function ($$v) {_vm.$set(_vm.typeform, "uuid", $$v)},expression:"typeform.uuid"}})],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('crupiers.form.username')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.userName.$error,
            },attrs:{"placeholder":_vm.$t('crupiers.form.username'),"type":"text","border":""},model:{value:(_vm.typeform.userName),callback:function ($$v) {_vm.$set(_vm.typeform, "userName", $$v)},expression:"typeform.userName"}}),(_vm.typesubmit && _vm.$v.typeform.userName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.userName.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('initSession.email')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.email.$error,
            },attrs:{"placeholder":_vm.$t('initSession.email'),"type":"text","border":""},model:{value:(_vm.typeform.email),callback:function ($$v) {_vm.$set(_vm.typeform, "email", $$v)},expression:"typeform.email"}}),(_vm.typesubmit && _vm.$v.typeform.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.email.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',{staticClass:"d-inline-flex align-items-center w-100"},[_vm._v(" "+_vm._s(_vm.$t('filter.client'))+": ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.client),expression:"typeform.client"}],staticClass:"form-select form-select-sm m-2 border-0 shadow-lg",attrs:{"name":"client","placeholder":"Seleccione.."},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "client", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.clients),function(item,index){return _c('option',{key:index,domProps:{"value":item._id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('crupiers.form.password')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.password.$error,
            },attrs:{"placeholder":_vm.$t('crupiers.form.password'),"type":"password","border":""},model:{value:(_vm.typeform.password),callback:function ($$v) {_vm.$set(_vm.typeform, "password", $$v)},expression:"typeform.password"}}),(_vm.typesubmit && _vm.$v.typeform.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.password.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])],1),_c('div',{staticClass:"mb-3 mb-0"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('form.save')))]),_c('vs-button',{attrs:{"type":"button","success":""},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" "+_vm._s(_vm.$t('form.abort'))+" ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }