<template>
  <Layout>
    <PageHeader :title="title" :moda_title="$t('crupiers.form.title')" ref="pageForm" :isAdmin="isItForAdmin()">
      <template>
        <PageForm :typeform="typeform" @closeModa="closeModa" />
      </template>
    </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                      }}</vs-option> </vs-select>&nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" primary class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0" style="overflow: visible">
              <b-table :items="tableData" :fields="makeFields()" responsive="sm">
                <!-- Column: Actions -->
                <template #cell(actions)="data" class="d-flex align-items-center">
                  <div>
                    <b-dropdown variant="link" no-caret>
                      <template #button-content>
                        <i class="fa fa-fw fa-bars font-size-16" />
                      </template>
                      <!-- <b-dropdown-item
                        :to="{
                          name: 'currencie-show',
                          params: { id: data.item._id },
                        }"
                      >
                        <i class="uil-file-alt m-2 font-size-18" />
                        <span class="align-middle ml-5">Detalles</span>
                      </b-dropdown-item> -->

                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="edit(data.item)">
                          <i class="uil-edit m-2 font-size-18" />
                          <span class="align-middle ml-5">{{ $t('actions.subItems.edit') }}</span>
                        </b-dropdown-item>
                      </div>

                      <div v-if="isItForAdmin()">
                        <b-dropdown-item @click="remove(data.item._id, data.item.name)">
                          <i class="mdi mdi-delete-outline m-2 font-size-18" />
                          <span class="align-middle ml-5">{{ $t('actions.subItems.delete') }}</span>
                        </b-dropdown-item>
                      </div>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PageForm from "./form.vue";
import Swal from "sweetalert2";

/**
 * Users-table component
 */
export default {
  page: {
    title: "crupiers Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, PageForm },
  data() {
    return {
      tableData: [],

      title: this.$t('sidebar.crupiers'),
      items: [
        {
          text: "Tables",
        },
        {
          text: "Crupiers",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      typeform: {
        name: "",
      },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
    this.getUserRole();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    isItForAdmin() {
      return ["ADMIN"].includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.$http
        .get("/crupiers")
        .then(({ data }) => {
          if (!data.crupiers) {
            return console.log("No hay crupiers", data.crupiers);
          }
          this.tableData = data.crupiers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModa() {
      this.$refs.pageForm.modal = false;

      this.typeform = {
        name: "",
      };
      this.getData();
    },
    edit(item) {
      this.typeform = item;
      this.$refs.pageForm.modal = true;
    },
    remove(id, name) {
      if (!id) return;

      Swal.fire({
        title: `¿Desea eliminar "${name}"?`,
        showDenyButton: true,
        icon: "info",
        confirmButtonText: "Sí",
        denyButtonText: "No",
      }).then(({ isConfirmed }) => {

        if (isConfirmed) {
          return this.$http
            .delete(`crupiers/${id}`)
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Cripier eliminado con exito",
                showConfirmButton: false,
                timer: 1500,
              });
              this.getData();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    makeFields() {
      const globalFields = [
        {
          key: "name",
          sortable: true,
          label: this.$t('form.name').toUpperCase(),
          class: "text-center",
        },
      ];
      const adminFields = [
        {
          key: "actions",
          sortable: false,
          label: this.$t('actions.text').toUpperCase(),
        },
      ];

      if (this.isItForAdmin()) {
        return [...globalFields, ...adminFields];
      }

      return globalFields;
    }
  },
  middleware: "authentication",
};
</script>
